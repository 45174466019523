import * as React from "react"
import { Box, Paragraph } from "theme-ui"

import { CheckoutLayout } from "../../layouts-v2/checkout"
import { Link } from "../Link"

import ReviewNoPayment from "./ReviewNoPayment"
import OrderSummary from "./OrderSummary"

import { useStore } from "../../../context/NewStoreContext"
import { ReviewAdyen } from "./ReviewAdyen"
import { hasAdyenEnabled } from "../utils/cart/hasAdyenEnabled"

const SwapCheckoutAdyen = () => {
  const store = useStore()

  React.useEffect(() => {
    const setPaymentSession = async () => {
      store.startCheckout.mutate(null, {
        onSuccess: (data) => {
          if (
            data.cart.payment_sessions.length &&
            data.cart.payment_sessions.some((ps) => ps.provider_id === "adyen")
          ) {
            store.pay.mutate({
              provider_id: "adyen",
            })
          }
        },
      })
    }

    if (
      store?.cart?.id &&
      !store?.pay?.isLoading &&
      !store?.startCheckout?.isLoading &&
      hasAdyenEnabled(store?.cart)
    ) {
      setPaymentSession()
    }
  }, [store?.cart])

  return store.cart?.id ? (
    <CheckoutLayout>
      <Box sx={{ display: [null, "flex"], minHeight: [null, "100vh"] }}>
        <OrderSummary order={store?.cart} canEdit={false} isSwap />
        <Box
          sx={{
            position: "relative",
            width: [null, "55%"],
            paddingBlockStart: [6, 23],
            paddingBlockEnd: [16, 23],
            "&::after": {
              content: "''",
              width: "100%",
              height: "100%",
              position: "fixed",
              zIndex: 2,
              top: 0,
              left: 0,
              opacity: 0,
              visibility: "hidden",
              backdropFilter: "blur(15px)",
              transition: "opacity .2s, visibility .2s",
            },
          }}
        >
          <Box
            sx={{
              position: [null, "sticky"],
              top: [null, 10],
              maxWidth: 147,
              marginInline: "auto",
              paddingInline: 4,
            }}
          >
            <Box sx={{ position: [null, "sticky"], top: [null, 0] }}>
              {store.cart?.total > 0 ? (
                <ReviewAdyen previousStep={() => null} isSwap />
              ) : (
                <ReviewNoPayment previousStep={() => null} isSwap />
              )}
            </Box>
            <Box
              sx={{
                display: ["flex", "none"],
                order: 13,
                gap: 12,
                marginBlockStart: 12,
              }}
            >
              <Box sx={{ fontSize: "xs" }}>
                <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
                  Terms and conditions
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/shipping-and-delivery">Shipping</Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/shipping-and-delivery">Return policy</Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/terms-and-conditions">Terms and conditions</Link>
                </Paragraph>
                <Paragraph>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </Paragraph>
              </Box>
              <Box sx={{ fontSize: "xs" }}>
                <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
                  Need assistance?
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="mailto:customercare@teklafabrics.com">
                    E-mail us
                  </Link>
                </Paragraph>
                <Paragraph>
                  <Link to="tel:+4552512545">+45 5251 2545</Link>
                </Paragraph>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </CheckoutLayout>
  ) : null
}

export default SwapCheckoutAdyen
