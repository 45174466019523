import * as React from "react"
import { Box, Paragraph } from "theme-ui"
import { Elements } from "@stripe/react-stripe-js"
import getStripe from "../../../utils/get-stripe"
import { BaseStripeElementsOptions } from "@stripe/stripe-js"

import { CheckoutLayout } from "../../layouts-v2/checkout"
import { Link } from "../Link"

import Review from "./Review"
import ReviewNoPayment from "./ReviewNoPayment"
import OrderSummary from "./OrderSummary"

import { useStore } from "../../../context/NewStoreContext"
import { navigate } from "gatsby-link"

const stripeOptions: BaseStripeElementsOptions = {
  fonts: [
    {
      family: "Unica77",
      src: "../../../fonts/Unica77/Unica77LLWeb-Light.woff2",
    },
    {
      family: "Unica77",
      src: "url(../../../fonts/Unica77/Unica77LLWeb-Light.woff)",
      weight: "400",
    },
  ],
  appearance: {
    theme: "stripe",
    labels: "floating",
    variables: {
      fontWeightNormal: "400",
      colorText: "#454545",
      colorTextPlaceholder: "#9e9e9d",
      borderRadius: "0",
      // @ts-expect-error
      borderColor: "#e3e3e3",
      fontFamily: `"Unica77", system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, sans-serif`,
    },
    rules: {
      ".Label--resting, .Label--floating": {
        color: "#9e9e9d",
      },
      ".Input": {
        boxShadow: "none",
        paddingLeft: "16px",
        paddingRight: "16px",
      },
      ".Input:focus": {
        boxShadow: "none",
        borderColor: "#e3e3e3",
      },
    },
  },
}

const SwapCheckout = () => {
  const store = useStore()
  const [clientSecret, setClientSecret] = React.useState<string>(null)

  React.useEffect(() => {
    const setPaymentSession = async () => {
      let paymentSessions = store.cart?.payment_sessions

      if (!paymentSessions?.length) {
        const { cart } = await store.startCheckout.mutateAsync()

        if (cart.payment_sessions?.length) {
          paymentSessions = cart.payment_sessions
        }
      }

      if (!paymentSessions?.length) {
        return
      }

      const stripeSession = store.cart?.payment_sessions?.find(
        (session) => session.provider_id === "stripe"
      )

      if (
        !clientSecret ||
        clientSecret !== stripeSession?.data?.client_secret
      ) {
        if (stripeSession?.data?.client_secret) {
          setClientSecret(stripeSession?.data?.client_secret as string)
        } else {
          const res = await store?.pay?.mutateAsync({
            provider_id: "stripe",
          })

          if (res?.cart?.payment_session?.data?.client_secret) {
            setClientSecret(
              res.cart.payment_session.data.client_secret as string
            )
          }
        }
      }
    }

    if (store.cart?.completed_at) {
      navigate(`/swaps/done?sct=${store.cart?.id}`)
    }

    if (
      store?.cart?.id &&
      !store?.pay?.isLoading &&
      !store?.startCheckout?.isLoading
    ) {
      setPaymentSession()
    }
  }, [store?.cart])

  return store.cart?.id ? (
    <CheckoutLayout>
      <Box sx={{ display: [null, "flex"], minHeight: [null, "100vh"] }}>
        <OrderSummary order={store?.cart} canEdit={false} isSwap />
        <Box
          sx={{
            position: "relative",
            width: [null, "55%"],
            paddingBlockStart: [6, 23],
            paddingBlockEnd: [16, 23],
            "&::after": {
              content: "''",
              width: "100%",
              height: "100%",
              position: "fixed",
              zIndex: 2,
              top: 0,
              left: 0,
              opacity: 0,
              visibility: "hidden",
              backdropFilter: "blur(15px)",
              transition: "opacity .2s, visibility .2s",
            },
          }}
        >
          <Box
            sx={{
              position: [null, "sticky"],
              top: [null, 10],
              maxWidth: 147,
              marginInline: "auto",
              paddingInline: 4,
            }}
          >
            <Box sx={{ position: [null, "sticky"], top: [null, 0] }}>
              {clientSecret && store.cart?.total > 0 ? (
                <Elements
                  stripe={getStripe()}
                  options={{
                    ...stripeOptions,
                    clientSecret,
                  }}
                  key={clientSecret}
                >
                  <Review
                    previousStep={() => null}
                    setClientSecret={setClientSecret}
                    isSwap
                  />
                </Elements>
              ) : (
                <ReviewNoPayment
                  previousStep={() => null}
                  setClientSecret={null}
                  isSwap
                />
              )}
            </Box>
            <Box
              sx={{
                display: ["flex", "none"],
                order: 13,
                gap: 12,
                marginBlockStart: 12,
              }}
            >
              <Box sx={{ fontSize: "xs" }}>
                <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
                  Terms and conditions
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/shipping-and-delivery">Shipping</Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/shipping-and-delivery">Return policy</Link>
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="/terms-and-conditions">Terms and conditions</Link>
                </Paragraph>
                <Paragraph>
                  <Link to="/privacy-policy">Privacy policy</Link>
                </Paragraph>
              </Box>
              <Box sx={{ fontSize: "xs" }}>
                <Paragraph sx={{ fontSize: "sm", marginBlockEnd: 4 }}>
                  Need assistance?
                </Paragraph>
                <Paragraph sx={{ marginBlockEnd: 1 }}>
                  <Link to="mailto:customercare@teklafabrics.com">
                    E-mail us
                  </Link>
                </Paragraph>
                <Paragraph>
                  <Link to="tel:+4552512545">+45 5251 2545</Link>
                </Paragraph>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </CheckoutLayout>
  ) : null
}

export default SwapCheckout
